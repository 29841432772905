.custom-category-mat-menu-class {
  overflow-y: hidden !important;
  min-width: 350px !important;
  max-height: 400px !important;

  unleash-search-input {
    position: sticky;
    top: 0px;
    display: block;
    height: 56px;
    z-index: $z-index-7;
    background: $color-grey-7;
    overflow: hidden;
  }
}
