.flightlog-detail-dialog {
  @include lt-md {
    height: 100vh;
    width: 100vw !important;
    max-width: unset !important;
  }

  > .mat-dialog-container {
    padding: 0px !important;
    height: unset;
  }
}
