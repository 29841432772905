.teams-backdrop + .cdk-overlay-connected-position-bounding-box {
  left: 200px !important;
  bottom: 16px !important;
  @include gt-xl {
    left: 224px !important;
  }
}

.teams-backdrop-collapsed + .cdk-overlay-connected-position-bounding-box {
  left: 76px !important;
  bottom: 16px !important;
}

.teams-backdrop-collapsed {
  left: 65px !important;
}

.teams-backdrop {
  left: 200px !important;
}

.teams-menu {
  max-width: 350px !important;
}

.team-switcher-dialog {
  width: 100vw;
  max-height: 500px;
  position: absolute !important;
  bottom: 0;
  animation-name: showMobileSort;
  animation-duration: 500ms;
  .mat-dialog-container {
    padding: 0 !important;
    border-radius: 8px 8px 0 0;
  }
}

@keyframes showMobileSort {
  from {
    bottom: -100%;
  }
  to {
    bottom: 0px;
  }
}
