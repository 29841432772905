// Use $spacing-* for fine-tuning the spacing between individual items
$spacing-1: 2px;
$spacing-2: 4px;
$spacing-3: 8px;
$spacing-4: 12px;
$spacing-5: 16px;
$spacing-6: 24px;
$spacing-7: 32px;
$spacing-8: 40px;
$spacing-9: 48px;
$spacing-10: 72px;

// Use $layout-* for spacing out components in the layout
$layout-1: 16px;
$layout-2: 24px;
$layout-3: 32px;
$layout-4: 48px;
$layout-5: 64px;
$layout-6: 96px;
$layout-7: 160px;
