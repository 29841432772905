@import 'styles/abstracts';

mat-expansion-panel-header, .info-header {

  .one-line {
    align-items: center;
    display: flex;
  }

  .two-line {
    display: flex;
    flex: auto;
    flex-direction: column;
  }

  .primary {
    @include text-5;
  }

  .secondary {
    color: $color-grey-5;
  }


  .avatar {
    @include avatar();
    margin-right: $spacing-5;
  }

  .mat-expansion-panel-header-title {
    display: flex;
    flex-grow: 7;
  }

  .mat-expansion-panel-header-title,
  .mat-expansion-panel-header-description {
    flex-basis: 0;
  }

  .mat-expansion-panel-header-description {
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
  }
}
