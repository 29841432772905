@import 'styles/abstracts/z-index';

.cdk-overlay-container {
  z-index: $z-index-65 !important;
}

.mat-dialog-container {
  padding: $spacing-6 !important;
}

.mat-dialog-actions {
  justify-content: flex-end;
  margin-bottom: -1 * $spacing-3 !important;
}
