@import 'styles/abstracts';

.trigger-point-menu__container {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  position: relative !important;

  .mat-menu-content {
    padding: $spacing-2 !important;
  }
}

.cdk-overlay-backdrop.trigger-point-menu__backdrop + .cdk-overlay-connected-position-bounding-box {
  max-width: none !important;
  margin-top: $spacing-4;

  &::after {
    top: -14px;
    left: 4px;
    width: 0px;
    height: 0px;
    content: '';
    position: absolute;
    border-left: 0.7rem solid transparent;
    border-right: 0.7rem solid transparent;
    border-bottom: 1rem solid white;
    z-index: $z-index-7;
  }
}
