@import 'styles/abstracts';

mat-button,
.mat-button,
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  mat-icon {
    @include md-icon-size(20px);
  }
}

.mat-button.mat-warn mat-icon {
  color: $color-red;
}
