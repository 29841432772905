@import 'styles/abstracts';

/* Add subtitle functionality and center description

UI
https://www.figma.com/file/a0xCrVh7indhZFOuPbADqq/Library?node-id=445%3A42

Implementation
<div class="u-checkbox__container">
  <mat-checkbox>
    3D Model and Point Cloud
  </mat-checkbox>
  <div class="u-checkbox__description">Visible in the ‘Models’ tab in your Library</div>
</div>
*/

.u-checkbox {
  &__container {
    display: flex;
    flex-direction: column;
    > .mat-checkbox {
      > .mat-checkbox-layout {
        display: inline-block;
      }
    }
  }

  &__description {
    margin-left: $spacing-6;
    color: $color-grey-6;
    cursor: pointer;

    &.is-disabled {
      cursor: unset;
    }
  }
}
