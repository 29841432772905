snack-bar-container.short-snackbar {
  min-width: 40px !important;
}

.snackbar-image-annotation-custom-position {
  @include gt-sm {
    margin-left: $sidebar-width + $spacing-5 !important;
    min-width: unset !important;
  }
}

.mat-snack-bar-container.center,
.mat-snack-bar-container.remote-cockpit-error-message {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: max-content;
}

.mat-snack-bar-container.remote-cockpit-error-message {
  @include text-5--bold;
}

.mat-snack-bar-container.mission-copied,
.mat-snack-bar-container.mission-renamed,
.mat-snack-bar-container.mission-uploaded,
.mat-snack-bar-container.markers-added {
  display: flex;
  align-items: center;
  justify-content: center;
  @include text-6;
  background-color: $color-grey-1;
  color: $color-grey-10;
}

.mat-snack-bar-container.mission-copied {
  min-width: 200px;
}

.mat-snack-bar-container.mission-renamed {
  min-width: 142px;
}

.mat-snack-bar-container.mission-uploaded {
  min-width: 167px;
}

.mat-snack-bar-container.markers-added {
  min-width: 100%;
}

.mat-snack-bar-handset {
  width: unset !important;
}

.mat-snack-bar-container.continue-watching {
  max-width: 70vw;
}
