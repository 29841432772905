@import 'styles/abstracts';

.library-image-viewer {
  width: 100vw !important;
  height: 100vh;
  max-width: unset !important;

  mat-dialog-container {
    padding: 0px !important;
    background: rgba(17, 17, 17, 0.75) !important;
    @include gt-xs {
      background: rgba(0, 0, 0, 0.85) !important;
    }
  }
}
