@import 'styles/abstracts';

.custom-mat-select-position-search {
  transform: translateX(-15px) translateY(32px) !important;

  @include gt-sm {
    transform: translateX(-110px) translateY(32px) !important;
  }
}

.custom-mat-select-search-size {
  max-height: unset !important;
  max-width: unset !important;
  overflow: unset !important;
  min-width: 350px !important;
}
