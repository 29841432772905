@import '@angular/material/theming';

.device-select {
  margin: 0 !important;
  padding: 0 !important;
  max-width: none !important;
  max-height: none !important;
  height: 100% !important;
  max-width: 359 !important;
  overflow-y: hidden !important;
  margin-top: $spacing-3 !important;

  .mat-menu-content {
    margin: 0 !important;
    padding: 0 !important;
  }

  //polygon for menu not available yet
  &::before {
    content: '';
    position: absolute;
    left: 10px;
    background: $color-grey-10;
    width: 20px;
    height: 12px;
    clip-path: polygon(
      0% 87%,
      4% 87%,
      9% 87%,
      12% 86%,
      15% 86%,
      18% 84%,
      21% 82%,
      25% 78%,
      27% 75%,
      29% 70%,
      31% 64%,
      33% 58%,
      34% 53%,
      36% 47%,
      38% 41%,
      39% 36%,
      41% 29%,
      43% 23%,
      45% 17%,
      48% 13%,
      50% 13%,
      52% 14%,
      54% 17%,
      56% 22%,
      58% 27%,
      59% 31%,
      62% 42%,
      64% 47%,
      65% 52%,
      67% 59%,
      69% 64%,
      71% 69%,
      73% 74%,
      75% 78%,
      78% 82%,
      81% 84%,
      84% 85%,
      87% 86%,
      90% 87%,
      92% 87%,
      95% 87%,
      98% 87%
    );
  }
}
.clear-filter {
  width: -webkit-fill-available;
  text-transform: none;
  padding: 0 $spacing-4 !important;
  margin: $spacing-5 !important;
  background-color: rgba($color-blue, 0.05);
  &.no-margin-bottom {
    margin-bottom: 0 !important;
  }
  &.no-margin-top {
    margin-top: 0 !important;
  }
  .mat-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: $spacing-3;
    letter-spacing: normal;
    .mat-icon {
      width: 16px;
      height: 16px;
      font-size: 16px;
    }
  }
}
