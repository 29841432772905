.location-menu {
  &::after {
    content: ' ';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 10px solid #fff;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
  }

  &.mat-menu-panel {
    width: 224px;
    margin-bottom: 30px;
    overflow: unset;
    transform: translateX(-88px);
  }

  .mat-menu-content {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    &:not(:empty) {
    }
  }
}
