// neutrals
$color-grey-0: #000;
$color-grey-1: #222;
$color-grey-2: #444;
$color-grey-3: #5e6366;
$color-grey-4: #666;
$color-grey-5: #8a8a8a;
$color-grey-6: #999;
$color-grey-7: #ccc;
$color-grey-8: #eee;
$color-grey-9: #f5f5f5;
$color-grey-10: #fff;
$color-grey-11: #fafafa;

// common
$color-blue: #006ba8;
$color-blue-muted: #2c4c5e;
$color-blue-light: #80b5d3;
$color-secondary: #26bb6f;
$color-red: #ce1c00;
$color-green: #5cb85c;
$color-green-dark: #158149;
$color-yellow: #f6b100;
$color-yellow-dark: #a47600;
$color-orange: #ffa500;

// annotations
$color-severity-low: #ff9337;
$color-severity-medium: #ff6932;
$color-severity-normal: #ff4c30;
$color-severity-high: #ff2e2f;
$color-severity-very-high: #c32d33;
$color-severity-unknown: $color-blue;

// zones
$color-css-green: rgb(0, 128, 0);
$color-css-blue: rgb(0, 0, 255);
$color-css-yellow: rgb(255, 255, 0);
$color-css-orange: rgb(255, 165, 0);
$color-css-purple: rgb(128, 0, 128);
$color-css-gold: rgb(255, 215, 0);
$color-css-skyblue: rgb(135, 206, 235);

// alerts
$color-alert-success: #5cb85c1a;
$color-alert-warning: #caecff;
$color-alert-warning-light: rgba(202, 236, 255, 0.4);
$color-alert-warning-dark: #d9f1ff;
$color-alert-error: rgba(206, 28, 0, 0.1);
$color-icon-alert-success: #5cb85c;
$color-icon-alert-warning: #486a7d;
$color-icon-alert-warning-light: rgba(74, 102, 118, 0.938);
$color-icon-alert-warning-dark: #1b4653;
$color-icon-alert-error: $color-red;

// form
$color-form-background: $color-grey-9;
$color-form-baseline: $color-grey-6;

// item hover
$color-item-hover: rgba(63, 81, 181, 0.05);

// shape annotations
$color-annotation-grey: rgb(186, 186, 186);
$color-annotation-blue: rgb(58, 125, 223);
$color-annotation-skyblue: rgb(39, 152, 177);
$color-annotation-purple: rgb(173, 145, 250);
$color-annotation-pink: rgb(243, 134, 168);
$color-annotation-red: rgb(250, 66, 47);
$color-annotation-orange: rgb(255, 105, 56);
$color-annotation-lightorange: rgb(255, 162, 72);
$color-annotation-yellow: rgb(252, 229, 128);
$color-annotation-lightgreen: rgb(65, 208, 139);
$color-annotation-green: rgb(32, 156, 94);
$color-annotation-black: rgb(78, 78, 78);
$color-annotation-shinegreen: rgb(0, 231, 175);

//highlight color
$color-highlight-text: #d6eeff;

//chip
$color-active-chip: #00af55;
$color-background-chip: #d8e4ec;

//header
$color-navigation-header: #404b5e;

//annotations-summary
$color-severity-5: #d30f0f;
$color-severity-4: #c96100;
$color-severity-3: #e79a00;
$color-severity-2: #fdc91b;
$color-severity-1: #ffdd6b;

//atlas
$color-snackbar-blue: #d9f1ff;
