@import 'styles/abstracts';

.mat-tab-label-content {
  font-weight: 500;
}

.mat-tab {
  text-transform: uppercase;
}

.mat-tab-label-active {
  color: $color-blue !important;
}

// Hide scroll on switch tab animation
// based on https://github.com/angular/components/issues/6104#issuecomment-493405106
.mat-tab-body-content {
  &.ng-animating {
    overflow: hidden !important;
  }
}

.mat-tab-label {
  opacity: 1 !important;
  font-size: 16px !important;
  color: #5e6366;
}

.mat-tab-label-content {
  font-weight: 400 !important;
}
