.custom-route-icon:has(.selected-waypoint) {
  z-index: $z-index-5 !important;
}
.selected-waypoint {
  background-color: $color-blue !important;
  border: none !important;
  color: $color-grey-10 !important;
  .triangle {
    border-bottom: 9px solid $color-blue !important;
  }
}

.limit-waypoint,
.intern-waypoint {
  box-sizing: border-box;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  @include text-7--bold;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    @include text-7--bold;
    height: 14px;
    line-height: unset;
  }
  .triangle-container {
    position: absolute;
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }
  .triangle {
    position: absolute;
    top: -6px;
    left: 6px;
    width: 0;
    height: 0;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    border-bottom: 9px solid $color-secondary;
  }
}
.intern-waypoint {
  background-color: $color-grey-10;
  border: 3.5px solid $color-secondary;
  span {
    height: 16px;
  }
}

.limit-waypoint {
  background-color: $color-secondary;
  color: $color-grey-10;
  span {
    height: 14px;
  }
}

.mission__icon-menu svg path {
  fill: $color-grey-6;
}
