// minimum: -1 (behind page)
// lowest visible layer: 0
// maximum: 2147483647

// Angular material values
// .cdk-overlay-container - modal background, modal container, popup menus, tooltips - 1000;
// <mat-sidenav> - 2
// 

// Leaflet values
// we should create a new stacking context in the container so we don't need insane values 
// .leaflet-zoom-box {
// 	z-index: 800;
// 	}

// .leaflet-pane         { z-index: 400; }
// .leaflet-tile-pane    { z-index: 200; }
// .leaflet-overlay-pane { z-index: 400; }
// .leaflet-shadow-pane  { z-index: 500; }
// .leaflet-marker-pane  { z-index: 600; }
// .leaflet-tooltip-pane   { z-index: 650; }
// .leaflet-popup-pane   { z-index: 700; }
// .leaflet-map-pane canvas { z-index: 100; }
// .leaflet-map-pane svg    { z-index: 200; }
// .leaflet-control {
// 	z-index: 800;
// 	}
// .leaflet-top,
// .leaflet-bottom {
// 	z-index: 1000;
// 	}


$z-index-0: 0;
$z-index-01: 1;
$z-index-02: 2;
$z-index-03: 3;
$z-index-04: 4;
$z-index-05: 5;
$z-index-10: 10;
$z-index-15: 50;
$z-index-2: 99;
$z-index-21: 100;
$z-index-3: 400;
$z-index-31: 401;
$z-index-32: 500;
$z-index-4: 999;
$z-index-5: 1000;
$z-index-51: 1001;
$z-index-52: 1010;
$z-index-53: 1100;
$z-index-55: 1500;
$z-index-56: 1600;
$z-index-6: 9999;
$z-index-65: 10000;
$z-index-7: 100000;
$z-index-8: 100099;


// // Copyright 2014 A Medium Corporation
// //
// // z-index.less
// // Medium.com's z-index scale. Z-index values should always be defined in z-index.less. This
// // allows us to at a glance determine relative layers of our application and prevents bugs
// // arrising from arbitrary z-index values. Do not edit the z-index scale! Only add application
// // scoped z-index values.

// //This is the SASS version modified by @toadkicker.


// // Z-Index Scale (private vars)
// // --------------------------------------------------
// $zIndex-1:   100;
// $zIndex-2:   200;
// $zIndex-3:   300;
// $zIndex-4:   400;
// $zIndex-5:   500;
// $zIndex-6:   600;
// $zIndex-7:   700;
// $zIndex-8:   800;
// $zIndex-9:   900;
// $zIndex-10: 1000;


// // Z-Index Applications
// // --------------------------------------------------
// $zIndex-1--screenForeground:        $zIndex-1;
// $zIndex-1--followUpVisibility:      $zIndex-1;
// $zIndex-1--prlWelcome:              $zIndex-1;
// $zIndex-1--appImageDropdown:        $zIndex-1;
// $zIndex-1--surfaceUnder:            $zIndex-1;
// $zIndex-1--blockGroup:              $zIndex-1;

// $zIndex-2--surfaceOver:             $zIndex-2;
// $zIndex-2--imagePickerControl:      $zIndex-2;
// $zIndex-2--collectionCardButton:    $zIndex-2;
// $zIndex-2--blockGroupButtonGroup:   $zIndex-2;
// $zIndex-2--blockGroupFocused:       $zIndex-2;
// $zIndex-2--blockGroupOverlay:       $zIndex-2;

// $zIndex-3--caption:                 $zIndex-3;
// $zIndex-3--blockInsertControl:      $zIndex-3;

// $zIndex-5--figureOverlay:           $zIndex-5;
// $zIndex-5--highlightMenu:           $zIndex-5;
// $zIndex-5--metabar:                 $zIndex-5;
// $zIndex-5--profileAvatar:           $zIndex-5;
// $zIndex-5--noteRecommendations:     $zIndex-5;
// $zIndex-5--collectionLogo:          $zIndex-5;

// $zIndex-6--matterLogo:              $zIndex-6;
// $zIndex-6--editorSidebar:           $zIndex-6;
// $zIndex-6--navOverlay:              $zIndex-6;

// $zIndex-7--nav:                     $zIndex-7;

// $zIndex-8--transitioningContainer:  $zIndex-8;
// $zIndex-8--panel:                   $zIndex-8;
// $zIndex-8--butterBar:               $zIndex-8;
// $zIndex-8--loadingBar:              $zIndex-8;
// $zIndex-8--zoomOverlay:             $zIndex-8;

// $zIndex-9--zoomOverlayTarget:       $zIndex-9;
// $zIndex-9--navOverlayTouch:         $zIndex-9;
// $zIndex-9--overlay:                 $zIndex-9;
// $zIndex-9--dialog:                  $zIndex-9;
// $zIndex-9--tooltip:                 $zIndex-9;

// $zIndex-10--dev:                    $zIndex-10;


// // Z-Index Mobile-Notes
// // --------------------------------------------------
// $zIndex-5--mobileNotesOverlay:    $zIndex-5;
// $zIndex-6--mobileNotesHighlight:  $zIndex-6;
// $zIndex-7--mobileNotesContainer:  $zIndex-7;
// $zIndex-8--mobileNotesDismiss:    $zIndex-8;