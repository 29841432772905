@import 'styles/abstracts';

.mat-paginator {
  @include text-6;
  padding-left: $spacing-2;
}

.mat-paginator-page-size,
.mat-paginator-page-size .mat-select-trigger,
.mat-paginator-page-size-select {
  font-size: 14px !important;
}
