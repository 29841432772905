/* Dialog remove padding move items dialog on library */
.move-items-dialog {
  width: 80vw !important;
  @include gt-xs {
    height: unset !important;
  }
  .mat-dialog-container {
    padding: 0;
    > :first-child {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    mat-dialog-content,
    div[mat-dialog-content] {
      flex-grow: 1;
      @include lt-sm {
        max-height: unset;
      }
    }
  }

  .mat-dialog-title {
    padding: 0 !important;
  }

  .mat-dialog-content {
    padding-left: $spacing-4 !important;
    padding-right: $spacing-4 !important;
    overflow-x: hidden;
  }

  .mat-dialog-actions {
    margin-bottom: (-1 * $layout-1) !important;
    padding-right: 0 !important;
  }
}
