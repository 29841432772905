.annotation-summary-mobile-menu .mat-dialog-container {
  position: absolute;
  height: fit-content;
  left: 0;
  bottom: 0;
  padding: 0 !important;
}

.mobile-filter__option.mat-option.mat-selected {
  color: $color-grey-1 !important;
}

.mat-pseudo-checkbox-checked {
  background: $color-secondary !important;
}

.mobile-filter__option .mat-option-text {
  height: 100%;
  display: flex;
  align-items: center;
}

.mobile-filter__checkbox .mat-checkbox-layout {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  .mat-checkbox-inner-container {
    margin: 0;
    margin-right: $spacing-3;
  }
}

.mobile-filter__option.mat-option.mat-active {
  background: transparent;
}
