@import 'styles/abstracts';

.unleash-ladda-spinner {
  display: inline-block;
  width: 19px;
  height: 19px;
  border: 4px solid $color-grey-8;
  border-bottom: 4px solid rgba(0, 0, 0, 0);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  z-index: $z-index-6;
  box-sizing: border-box;
  margin-top: $spacing-1;
  margin-bottom: $spacing-2;
  margin-right: $spacing-1;

  &--disabled {
    border-color: $color-grey-6;
    border-bottom: 4px solid $color-grey-7;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
