@import 'styles/abstracts';

.remote-cockpit-mobile {
  position: absolute !important;
  width: 100vw;
  max-width: 100vw !important;
  animation: moveUp 0.5s forwards;
  & .mat-dialog-container {
    border-radius: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 0 !important;
  }
}

@keyframes moveUp {
  0% {
    bottom: -300px;
  }
  100% {
    bottom: 0;
  }
}

.remote-cockpit-action-dialog {
  max-width: 90vw !important;
  @include gt-xs {
    width: 800px !important;
  }
  .mat-dialog-container {
    padding: $spacing-5 $spacing-6 !important;
    @include gt-xs {
      padding: $spacing-6 !important;
    }
  }
}
