.team-snackbar {
  text-align: center;
  position: relative;
  bottom: 80px;
  @include gt-sm {
    bottom: 0;
  }
  .mat-simple-snackbar {
    justify-content: center;
  }
}
