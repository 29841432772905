@import 'styles/abstracts';

.manage-zones-dialog {
  max-width: unset !important;
  width: 100vw !important;
  height: 100vh !important;
  position: static;

  @include gt-sm {
    margin-left: 57px;
  }

  app-manage-zones-dialog {
    display: flex;
    flex-direction: column;
  }

  .mat-dialog-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0px !important;

    @include lt-md {
      padding-bottom: $spacing-3 !important;
    }
  }

  .mat-dialog-actions {
    justify-content: flex-end;
    margin: 0 !important;
    padding: 0 !important;
    padding-top: $spacing-3 !important;
    min-height: unset !important;
  }
}
