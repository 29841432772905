.date-select {
  max-width: none !important;
  max-height: none !important;
  margin-top: $spacing-3 !important;
  .mat-menu-content {
    margin: 0 !important;
    padding: 0 !important;
  }
  .mat-form-field:not(.timestamp__input) {
    height: 56px;
    width: 178px;
    padding-top: 0 !important;
    padding-right: $spacing-3 !important;
    .mat-form-field-flex {
      display: flex !important;
      flex-direction: row-reverse !important;
      height: 52px;
      .mat-form-field-label {
        color: $color-grey-5 !important;
      }
      .mat-datepicker-toggle-default-icon {
        color: $color-grey-5;
      }
    }
    .mat-form-field-underline {
      bottom: 0 !important;
    }
    .mat-form-field-wrapper {
      padding-bottom: 0;
    }
  }
  .date-form {
    &__field {
      width: 123px;
    }
    &__time-container {
      display: flex;
      flex-direction: column;
    }
  }
  .date-tab .mat-tab-label-content {
    @include text-6;
    font-weight: 500 !important;
  }
}
