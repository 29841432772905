// Icon sizing
@mixin md-icon-size($size: 24px) {
  font-size: $size;
  height: $size;
  width: $size;
  line-height: $size;
}

//
// Avatar
//
@mixin avatar() {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: $br-50;
  flex-shrink: 0;
  height: 40px;
  width: 40px;
}

// Z-index - Create a new local stacking context
// https://medium.com/hackernoon/my-approach-to-using-z-index-eca67feb079c
@mixin new-stacking-context {
  position: relative;
  z-index: 0;
}

//
// Make non-link appear as link
//
@mixin link {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

@mixin reset-box-sizing {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// to reset h1, h2, h3, h4, h5, p {@include reset-heading}
@mixin reset-heading {
  margin-top: 0px;
  margin-bottom: 0px;
}

@mixin fade-in-out-background($duration: 0.5s, $easing: ease-in-out, $background-color: $color-grey-10) {
  background-color: transparent;
  transition: background-color $duration $easing;

  &:hover {
    background-color: $background-color;
  }
}

@mixin ellipsis($width: 50px) {
  white-space: nowrap;
  width: $width;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

@mixin ellipsis($max-width) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: $max-width;
}

@mixin severity-styles($levels, $color, $size) {
  @each $level, $bg-color in $levels {
    &__#{$level} {
      border-radius: 50%;
      width: $size;
      height: $size;
      color: $color;
      display: flex;
      align-items: center;
      justify-content: center;
      @if $bg-color != null {
        background-color: $bg-color;
      } @else {
        display: none;
      }
    }
  }
}
