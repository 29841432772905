.global-process-bar {
  width: 100%;
  max-height: 70px;
  min-height: 70px;
  background-color: $color-navigation-header;
  color: $color-grey-10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  overflow: hidden;

  &--white {
    background-color: $color-grey-10;
    color: $color-grey-1;

    .global-process-bar__title {
      color: $color-grey-2;
    }
    .global-process-bar__title--subtitle {
      color: $color-grey-4;
    }
    .global-process-bar__button {
      color: $color-grey-1;
    }
  }

  &--single-line {
    max-height: 52px;
    min-height: 52px;

    .global-process-bar__container {
      align-items: center;
      height: 52px;
      box-sizing: border-box;

      .global-process-bar__button {
        margin-top: -6px !important;
      }
    }
  }

  &__container {
    display: flex;
    align-items: flex-start;
    overflow: hidden;
    padding: $spacing-4 $spacing-6;
  }

  &__button {
    color: $color-grey-10;
    align-self: flex-start;
    margin-top: -6px !important;

    &--menu {
      margin-top: 0px !important;
      @include gt-xs {
        display: none;
      }
    }
  }

  &__description {
    width: 100%;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: $spacing-1;
    overflow: hidden;
  }

  &__title {
    margin: 0px;
    @include text-4;
    color: $color-grey-10;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &--text-5 {
      @include text-5;
    }

    &--subtitle {
      @include text-5;
      display: flex;
      align-items: center;
      color: $color-grey-7;
      white-space: nowrap;
      overflow-y: auto;
    }
  }

  &__text {
    @include text-4--bold;

    &--spacing {
      margin: 0px $spacing-3;
    }
  }

  &__breadcrumb {
    @include text-5;
    color: $color-grey-6;
    display: flex;
    align-items: center;
    height: 24px;
  }

  &__dropdown {
    display: none;
    @include gt-xs {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: $spacing-4;
    }

    &-list {
      max-height: 300px;
    }
    &-menu {
      color: $color-grey-10;
      opacity: 0.75;
      text-transform: none;
      @include text-5;
      letter-spacing: 0;
      padding: 0 $spacing-4;
    }
    &-point-icon {
      font-size: 4px;
      width: 4px;
      height: 4px;
      display: flex !important;
      align-items: center;
      justify-content: center;
    }
    &-select-icon {
      font-size: 15px;
      width: 15px;
      height: 15px;
      margin-left: $spacing-2;
      display: inline-flex !important;
      align-items: center;
      justify-content: center;
    }
  }
  &__point {
    width: 4px;
    height: 4px;
    background-color: rgba($color-grey-10, 0.75);
    border-radius: 50%;
    margin: 0 $spacing-4;
  }

  .lowercase {
    text-transform: lowercase;
  }
}

::ng-deep .global-process-bar__dropdown-menu .mat-button-wrapper {
  display: flex;
  align-items: center;
}

::ng-deep .global-process-bar__breadcrumbs {
  display: inline;
}
