.marker-state-menu {
  left: 250px;
  top: -25px;
  position: absolute !important;

  &__item {
    text-overflow: unset !important;
    @include text-6;
    height: 40px !important;
    display: flex !important;
    align-items: center;
  }
  .custom-icon {
    width: 20px;
    height: 20px;
    margin-right: $spacing-4 !important;
  }
  .check-icon {
    width: 20px;
    height: 20px;
    font-size: 20px;
    margin-left: $spacing-3;
    margin-right: 0 !important;
    margin-bottom: $spacing-2;
    color: $color-grey-1;
  }
}
