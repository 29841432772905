.right-submenu-option {
  position: absolute;
  top: 60px;
  right: 0px;
}
.right-menu-container {
  &.mat-menu-panel {
    min-width: 236px;
  }
}
.right-menu-option {
  @include text-5;
  & > mat-icon {
    margin-right: 18px !important;
    margin-top: 5px;
    & > svg {
      width: 20px !important;
      height: 16px !important;
    }
  }
  & > .sorting {
    & > svg {
      width: 17px !important;
      height: 18px !important;
      filter: grayscale(100%) brightness(122%);
    }
  }
}
