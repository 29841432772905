@import 'styles/abstracts';

.disabled-toogle-label {
  display: flex;
  align-items: center;
  @include text-7;
  text-align: right;
  padding-right: $spacing-2;

  &.disabled {
    color: $color-grey-3;
  }

  &.enabled {
    color: $color-blue;
  }
}

:host ::ng-deep .mat-slide-toggle-content {
  @include text-7;
  text-align: right;
  color: $color-grey-3;
}

:host ::ng-deep .mat-checked .mat-slide-toggle-content {
  text-align: right;
  color: $color-blue;
}

:host ::ng-deep .mat-slide-toggle-bar {
  background-color: transparentize($color-blue, 0.46);
}

:host ::ng-deep .mat-slide-toggle-thumb {
  background-color: $color-blue;
}
