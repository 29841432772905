.leaflet-top,
.leaflet-bottom {
  z-index: $z-index-32;
}
.leaflet-bar a,
.leaflet-bar a:hover {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

/* offset icons */
.leaflet-draw-actions {
  left: 30px;
}

/* ================================================================== */
/* Draw toolbar
/* ================================================================== */

.leaflet-draw-toolbar .leaflet-draw-draw-polyline {
  background-position: 0px 0px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-polyline {
  background-position: 2px 1px;
}

.leaflet-draw-toolbar .leaflet-draw-draw-polygon {
  background-position: -29px 0px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-polygon {
  background-position: -27px 1px;
}

.leaflet-draw-toolbar .leaflet-draw-draw-rectangle {
  background-position: -60px 0px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-rectangle {
  background-position: -58px 1px;
}

.leaflet-draw-toolbar .leaflet-draw-draw-circle {
  background-position: -90px 0px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-circle {
  background-position: -88px 1px;
}

.leaflet-draw-toolbar .leaflet-draw-draw-marker {
  background-position: -120px 0px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-marker {
  background-position: -118px 1px;
}

.leaflet-draw-toolbar .leaflet-draw-draw-circlemarker {
  background-position: -271px 0px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-circlemarker {
  background-position: -269px 1px;
}

/* ================================================================== */
/* Edit toolbar
/* ================================================================== */

.leaflet-draw-toolbar .leaflet-draw-edit-edit {
  background-position: -152px -2px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-edit-edit {
  background-position: -150px -1px;
}

.leaflet-draw-toolbar .leaflet-draw-edit-remove {
  background-position: -180px 0px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-edit-remove {
  background-position: -180px -1px;
}

.leaflet-draw-toolbar .leaflet-draw-edit-edit.leaflet-disabled {
  background-position: -212px -2px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-edit-edit.leaflet-disabled {
  background-position: -210px -1px;
}

.leaflet-draw-toolbar .leaflet-draw-edit-remove.leaflet-disabled {
  background-position: -240px 0px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-edit-remove.leaflet-disabled {
  background-position: -240px -2px;
}

.leaflet-container {
  font-family: $font-family;
}

.leaflet-left .leaflet-control {
  margin-left: 10px;
}

.leaflet-top .leaflet-control {
  margin-top: 8px;
}

.leaflet-tooltip {
  padding: $spacing-2;
}

.tooltip {
  @include text-7;
  margin: 0;
}

.leaflet-pane .leaflet-marker-icon {
  &.device-icon {
    position: absolute;
    display: flex;
    align-items: center;
    white-space: nowrap;

    img {
      border-radius: 50%;
      background-color: $color-grey-10;
      width: $layout-2;
      height: $layout-2;
      padding: $spacing-2;
      margin-right: $spacing-3;
    }

    > span {
      @include text-7--bold;
      font-weight: 600;
      color: $color-grey-10;
      text-shadow: $color-grey-0 1px 1px 1px;
      filter: drop-shadow(1px 1px 3px $color-grey-0);
    }

    .triangle-circle {
      position: absolute;
      top: -18px;
      left: -16px;
      width: 61px;
      height: 76px;
      display: flex;
      align-items: center;

      .triangle {
        width: 0;
        height: 0;
        border-right: 7px solid transparent;
        border-left: 7px solid transparent;
        border-bottom: 14px solid $color-grey-10;
        transform: rotate(-90deg);
      }

      .circle {
        border-radius: 50%;
        width: 8px;
        height: 8px;
        background-color: $color-grey-10;
        margin: $spacing-2;
      }
    }
  }
}

.job-shape-edit {
  width: 16px !important;
  height: 16px !important;
  margin-top: -8px !important;
  margin-left: -8px !important;
  background-color: $color-yellow;
  &::after {
    content: '+';
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    @include text-5--bold;
    color: $color-grey-10;
    line-height: normal;
    width: 100%;
    height: 100%;
  }
}

.middle-waypoint {
  width: 14px !important;
  height: 14px !important;
  margin-top: -6px !important;
  margin-left: -6px !important;
  border-radius: 2px;
  background-color: rgba($color-grey-10, 0.8);
  &__popup {
    @include text-6;
    color: $color-grey-10;
  }
  &::after {
    content: '+';
    position: relative;
    top: -2.5px;
    left: 3px;
    @include text-7;
    color: $color-secondary;
    font-weight: bold;
  }
  &:hover {
    background-color: $color-secondary;
    &::after {
      color: $color-grey-10;
    }
  }
}

.leaflet-popup-tip-container {
  display: none;
}

.leaflet-popup.middle-waypoint__popup-container {
  left: 20px !important;
  top: -18px !important;
  & .leaflet-popup-content {
    margin: 0;
  }
  & .leaflet-popup-content-wrapper {
    width: 118px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-blue-muted;
  }
}
.custom-route-waypoint {
  min-width: 20px;
  margin-left: -10px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.offline-popup .leaflet-popup-content-wrapper {
  background-color: $color-blue-muted;
  color: $color-grey-10;
  border-radius: 4px;
  & .leaflet-popup-content {
    display: block;
    box-sizing: border-box;
    width: fit-content;
    @include text-6;
    margin: 0;
    padding: $spacing-3;
    text-align: center;
  }
}

.custom-cluster {
  &__container {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    opacity: 1;
    box-shadow: 0 0 8px 0px $color-grey-2;
  }
  &__icon {
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    @include text-7--bold;
    color: $color-grey-11;
    &.black {
      color: $color-grey-1;
      font-weight: normal;
    }

    &.size-indefinite {
      background-color: $color-grey-2;
    }

    &.size-small {
      background-color: rgba(181, 226, 140, 1);
    }
    &.size-medium {
      background-color: rgba(241, 211, 87, 1);
    }
    &.size-large {
      background-color: rgba(253, 156, 115, 1);
    }
  }
}

.leaflet-control-geosearch {
  .leaflet-bar-part {
    width: 40px !important;
    height: 40px !important;
    &:before,
    &:after {
      border-color: $color-grey-1;
    }
    &:before {
      top: 24px !important;
      left: 20px !important;
      width: 10px !important;
    }
    &:after {
      top: 11px !important;
      left: 11px !important;
      width: 12px !important;
      height: 12px !important;
    }
  }

  form input {
    width: 220px;
    height: 40px;
    border-top-right-radius: $spacing-2;
    border-bottom-right-radius: $spacing-2;
    @include text-6;
  }

  a.reset {
    color: $color-grey-10;
    height: 40px;
    width: 40px;
    border-radius: 4px;
    &:before {
      content: '';
      position: absolute;
      top: 18px;
      left: 14px;
      transform: rotate(45deg);
      background-color: $color-grey-1;
      width: 14px;
      height: 2px;
    }
    &:after {
      content: '';
      position: absolute;
      top: 18px;
      left: 14px;
      transform: rotate(-45deg);
      background-color: $color-grey-1;
      width: 14px;
      height: 2px;
    }
  }
}

.leaflet-control-minimap {
  border-radius: 4px;
  border: none;
}

.plus-marker {
  background-color: $color-grey-10;
  color: $color-orange;
  font-size: 11px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.flight-path-marker {
  background-color: $color-orange;
  border-radius: 50%;
  color: $color-grey-10;
}

.new-flight-path-marker {
  background-color: $color-grey-10;
  color: $color-orange;
  border-radius: 50%;
}

.pole-created-flight-path-marker {
  background-color: $color-green;
  border-radius: 50%;
  color: $color-grey-10;
}

.created-flight-path-marker {
  background-color: $color-grey-10;
  border-radius: 50%;
  color: $color-green;
}

.leaflet-tooltip.assetId-tooltip {
  background: $color-blue-muted;
  border: none;
  box-shadow: none;
  color: $color-grey-10;
  @include text-7--bold;
  &::before {
    display: none;
  }
}

.job-shape-vertex {
  background-color: $color-grey-10;
  border-radius: 50%;
  width: 12px !important;
  height: 12px !important;
  margin-left: -6px !important;
  margin-top: -6px !important;
}

.leaflet-popup.feature-popup {
  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: $color-grey-7;
    border: 4px solid $color-grey-11;
  }

  ::-webkit-scrollbar-track {
    background-color: $color-grey-11;
    border: 4px solid transparent;
  }

  .content {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;

    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .key {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .value {
        padding-left: $spacing-3;
        max-width: 140px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  .leaflet-popup-content-wrapper {
    @include text-5;
    background: $color-grey-11;
    color: $color-grey-1;
    border-radius: 4px;
    width: 304px;
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: auto;
    box-sizing: border-box;
    box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.07);
    display: flex;

    .leaflet-popup-content {
      margin: 0;
      display: flex;
      flex-direction: column;
      img {
        height: 100%;
        width: 100%;
        max-width: 286px;
        object-fit: contain;
      }
    }
  }

  .popup-image {
    padding: $spacing-3;
    padding-bottom: $spacing-2;
  }
  .leaflet-popup-tip-container {
    width: 30px;
    height: 15px;
  }

  .leaflet-popup-tip {
    background: transparent;
    border: none;
    box-shadow: none;
  }

  .property {
    margin: 0 $spacing-5;
    margin-top: $spacing-3;
    &:last-child {
      margin-bottom: $spacing-3;
    }
    &__key,
    &__value {
      margin: 0;
      padding: 0;
      word-break: break-word;
      word-wrap: break-word;

      a {
        color: $color-grey-1;
        text-decoration: none;
      }
    }

    &__key {
      @include text-7;
      color: $color-grey-4;
      text-transform: capitalize;
    }
    &__value {
      @include text-6;
      color: $color-grey-1;
    }
    &__header {
      display: flex;
      align-items: center;
      margin: 0;
      background-color: $color-grey-9;
      color: $color-grey-1;
      padding: $spacing-3 $spacing-5;
      gap: $spacing-2;
      width: 270px;
      &-image {
        width: 16px !important;
        height: 16px !important;
      }
      &-text {
        @include text-6;
        word-break: break-word;
      }
    }
  }
}

.leaflet-popup.marker-state-hover {
  position: absolute;
  left: 25px !important;
  bottom: -100px !important;
  .leaflet-popup-content {
    margin: 0;
    min-width: 218px;
  }
  .leaflet-popup-content-wrapper {
    border-radius: 4px;
  }
}

.leaflet-popup.marker-state {
  .leaflet-popup-content {
    margin: 0;
    min-width: 218px;
  }
  .leaflet-popup-content-wrapper {
    border-radius: 4px;
  }
}
