@import 'styles/abstracts';

.tags-menu-library {
  max-width: unset !important;

  &.filterButton {
    margin-top: $spacing-5;
  }

  .mat-menu-content {
    width: 313px;
  }
}

.tags-search__form {
  .mat-form-field-underline {
    display: none;
  }
  .mat-form-field-flex {
    border-bottom: 1px solid $color-grey-7;
  }
}
