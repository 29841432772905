.leaflet-sbs-range {
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: 999;
}

.leaflet-sbs-divider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -2px;
  width: 4px;
  background-color: #abdebe;
  pointer-events: none;
  z-index: 999;
}
