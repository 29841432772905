.global-action-bar {
  &--sticky {
    position: sticky;
    top: 0;
  }

  overflow-y: auto;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  background-color: $color-blue;
  display: flex;
  align-items: center;
  z-index: $z-index-10;
  min-height: 50px;

  @include gt-sm {
    padding-left: $spacing-3;
    padding-right: $spacing-3;
  }

  &--hide-bar {
    @include gt-xs {
      display: none;
    }
  }

  &--mobile-menu {
    z-index: $z-index-05;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: rgba(34, 34, 34, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @include gt-xs {
      position: unset;
    }
  }

  &__button {
    height: 50px;
    color: $color-grey-10;
    @include text-6--bold;
    letter-spacing: 0.75px;
    line-height: 21px;
    min-width: fit-content !important;

    &.mat-button-disabled {
      color: $color-grey-10 !important;
      opacity: 0.4;
    }
  }

  &__mobile-menu-button {
    width: 100%;
    display: flex !important;
    align-items: flex-start;
    @include gt-xs {
      width: auto;
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    &--with-padding {
      padding: $spacing-3;
    }
    &--with-margin {
      margin: $spacing-2;
    }
  }
  &__icon-svg {
    width: 24px !important;
    height: 24px !important;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    font-size: 21px;
    svg path {
      fill: currentColor;
    }
    &--with-margin {
      margin: $spacing-2;
    }
  }
  &__icon-inline {
    display: inline-block;
  }

  &__options {
    border-radius: 8px 8px 0 0;
    opacity: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: $spacing-3;
    background-color: $color-grey-10;
    animation-name: show-menu;
    animation-duration: 400ms;

    &--mobile-menu-hidden {
      display: flex;
      flex-direction: row;
      background-color: $color-blue;
    }

    @include gt-xs {
      border-radius: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      background-color: $color-blue;
      color: $color-grey-10;
      margin: 0;
      position: unset;
      animation: none;
      flex-wrap: wrap;
    }
  }

  &__loader {
    position: absolute !important;
    width: 100%;
  }

  &__divider {
    width: 100%;
    @include gt-xs {
      display: none !important;
    }
  }

  &__button--menu {
    color: $color-grey-10;
  }

  &--z-index-1 {
    z-index: $z-index-01;
  }
  &__menu-item {
    display: flex !important;
    align-items: center;
  }
  &.end {
    justify-content: end;
  }
}

.mat-red {
  color: $color-red;
}

.global-action-bar__options .global-action-bar__mobile-menu-button:first-child {
  margin-top: $spacing-5;
}

.global-action-bar__options .global-action-bar__mobile-menu-button:last-child {
  margin-bottom: $spacing-5;
}

.global-action-bar__mobile-menu-button .mat-button-wrapper,
.global-action-bar__button .mat-button-wrapper {
  display: flex;
  align-items: center;
  gap: $spacing-2;
}
