// Break points from angular flex-layout
// https://github.com/angular/flex-layout/wiki/Responsive-API#mediaqueries-and-aliases
$screen-xs-max: 599px;
$screen-sm-min: 600px;
$screen-sm-max: 959px;
$screen-md-min: 960px;
$screen-md-max: 1279px;
$screen-lg-min: 1280px;
$screen-lg-max: 1919px;
$screen-xl-min: 1920px;
$screen-xl-max: 2559px;
$screen-xxl-min: 2560px;
$screen-xxl: 3200px;
$screen-xxl-max: 5000px;
$screen-landscape: 375px;

//TODO: refactor so only gt-* mixins are used - components should be resonsive from mobile up, making the other mixins redundant (i.e. applying mobile-specific styles over desktop, or only applying styles in a fixed range)

@mixin xs {
  @media (max-width: #{$screen-xs-max}) {
    @content;
  }
}

@mixin sm {
  @media (min-width: #{$screen-sm-min}) and (max-width: #{$screen-xs-max}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$screen-md-min}) and (max-width: #{$screen-md-max}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$screen-lg-min}) and (max-width: #{$screen-lg-max}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{$screen-xl-min}) and (max-width: #{$screen-xl-max}) {
    @content;
  }
}

@mixin xxl {
  @media (min-width: #{$screen-xxl-min}) and (max-width: #{$screen-xxl-max}) {
    @content;
  }
}

@mixin lt-sm {
  @media screen and (max-width: #{$screen-xs-max}) {
    @content;
  }
}

@mixin lt-md {
  @media screen and (max-width: #{$screen-sm-max}) {
    @content;
  }
}

@mixin lt-lg {
  @media screen and (max-width: #{$screen-md-max}) {
    @content;
  }
}

@mixin lt-xl {
  @media screen and (max-width: #{$screen-lg-max}) {
    @content;
  }
}

@mixin lt-xxl {
  @media screen and (max-width: #{$screen-xl-max}) {
    @content;
  }
}

@mixin gt-xs {
  @media screen and (min-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin gt-sm {
  @media screen and (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin gt-md {
  @media screen and (min-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin gt-lg {
  @media screen and (min-width: #{$screen-xl-min}) {
    @content;
  }
}

@mixin gt-xl {
  @media screen and (min-width: #{$screen-xxl-min}) {
    @content;
  }
}

@mixin gt-xxl {
  @media screen and (min-width: #{$screen-xxl}) {
    @content;
  }
}

@mixin landscape {
  @media screen and (max-height: #{$screen-landscape}) {
    @content;
  }
}
