.ladda-spinner {
  div[role='progressbar'] {
    position: relative;

    > div {
      display: none;
    }

    &:before {
      content: '';
      width: 20px;
      height: 20px;
      background: url('/assets/icons/spinner.svg') no-repeat center center;
      background-size: contain;
      animation: ladda-spinner 0.5s infinite linear;
      opacity: 0.2;
      display: block;
      position: absolute;
      top: -10px;
      right: -2px;
    }
  }
}

@keyframes ladda-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
