.mat-tooltip {
  @include text-6;
  background-color: $color-blue-muted;
  padding: $spacing-3;
  max-width: 400px;
}

.mtx-tooltip {
  @include text-6;
  background-color: $color-blue-muted;
  padding: $spacing-3;
  max-width: 400px;
}

.sidebar-tooltip {
  background-color: $color-blue-muted !important;
}

.upload-missions-warning {
  max-width: 150px !important;
  text-align: center;
}

.mission-waypoint-tooltip {
  margin-bottom: 0 !important;
}

.mtx-tooltip {
  max-width: unset !important;
}
