.color-list {
  min-height: 48px !important;
  display: flex;
  align-items: center;
  .mat-menu-content {
    display: flex;
    gap: $spacing-4;
    padding: 0 $spacing-5;
  }
}
