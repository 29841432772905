.action-menu-context-library {
  .mat-menu-item {
    display: flex;
    align-items: center;
  }
}

.action-menu-context-library-submenu {
  max-width: unset !important;
}
