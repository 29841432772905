@import 'styles/abstracts';

/*
  Based on
  https://stackoverflow.com/questions/47455074/add-custom-color-variables-in-angular-material-2#54447519
*/

.mat-success {
  background-color: unset !important;
  color: $color-green !important;
}

.mat-success[disabled] {
  background-color: $color-grey-5 !important;
}
