.hover-menu.mat-menu-panel {
  max-height: 217px;
  max-width: 224px;
}

.hover-menu .mat-menu-content {
  padding: $spacing-2 !important;
}
.annotations-backdrop {
  display: none;
}
