.hsds-beacon .is-configDisplayLeft {
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;

  @media (min-width: 450px) and (max-width: 960px) {
    left: auto !important;
    bottom: 65px !important;
    right: 0 !important;
  }

  @media (min-width: 960px) {
    left: 65px !important;
    bottom: 10px !important;
    right: auto;
  }
}

.hsds-beacon .is-configDisplayRight {
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;

  @media (min-width: 450px) and (max-width: 960px) {
    left: auto !important;
    bottom: 110px !important;
    right: 20px !important;
  }

  @media (min-width: 960px) {
    left: auto !important;
    bottom: 110px !important;
    right: 20px !important;
  }
}

.hsds-beacon .kuAMuF{
  max-height: 500px !important;
}