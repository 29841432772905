.atlas-menu .action-bar__disabled svg path {
  fill: $color-grey-6;
}

.atlas-layer-menu {
  width: 252px;
}

.atlas-device-menu {
  width: 233px;
}

.layer__custom-icon svg path {
  fill: $color-grey-6;
}

.atlas-snackbar-message--1,
.atlas-snackbar-message--2,
.atlas-snackbar-message--3,
.atlas-snackbar-message--4,
.atlas-snackbar-message--0 {
  max-width: 800px !important;
  background-color: $color-snackbar-blue;
  position: absolute;
  bottom: 0;
  min-width: fit-content !important;
}

.atlas-snackbar-message--0 {
  left: 41%;
}

.atlas-snackbar-message--1 {
  left: calc(510px + ((100vw - 510px) / 2));
  transform: translateX(-50%) !important;
}

.atlas-snackbar-message--2 {
  left: calc(60px + ((100vw - 60px) / 2));
  transform: translateX(-50%) !important;
}

.atlas-snackbar-message--3 {
  left: calc(634px + ((100vw - 634px) / 2));
  transform: translateX(-50%) !important;
}

.atlas-snackbar-message--4 {
  left: calc(184px + ((100vw - 184px) / 2));
  transform: translateX(-50%) !important;
}

.layer__group.mat-menu-panel {
  min-height: unset;
}
