@import 'styles/abstracts';

.rule-alert-panel {
  min-width: calc(100% + 0px) !important;

  @include gt-sm {
    min-width: calc(100% + 135px) !important;
  }

  .mat-option {
    height: 100% !important;
  }
}
