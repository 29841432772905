.video-ai-menu.mat-menu-panel {
  max-width: 100%;
  .mat-menu-item {
    width: 100%;
    min-width: fit-content;
  }
  .mat-menu-content {
    width: max-content;
  }
}
