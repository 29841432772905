@font-face {
  font-family: 'fontello';
  src: url('/assets/fonts/fontello.eot?7339157');
  src: url('/assets/fonts/fontello.eot?7339157#iefix') format('embedded-opentype'),
    url('/assets/fonts/fontello.woff?7339157') format('woff'),
    url('/assets/fonts/fontello.ttf?7339157') format('truetype'),
    url('/assets/fonts/fontello.svg?7339157#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
