.confirm-scene-mapping-snackbar {
  &__marker-delete {
    min-width: unset !important;
    position: relative;
    left: 368px;
    transform: translateX(-100%) !important;
  }

  &__scene-delete,
  &__scene-add {
    min-width: unset !important;
    position: relative;
    left: 368px;
    transform: translateX(-60%) !important;
  }
}
