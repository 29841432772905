.no-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.small-icon {
  @include text-5;
  line-height: 1em;
}

.disable-text-selection {
  user-select: none;
}

.center-horizontal-and-vertical {
  display: flex;
  align-items: center;
  justify-content: center;
}

.full-screen {
  height: calc(100% - 140px); // TODO: Remove magic number
  overflow: hidden;
}

.link {
  color: $color-blue;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
}

.spacer {
  flex: 1;
}

.w-100 {
  width: 100% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.display-none {
  display: none;
}
