app-root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
  box-sizing: border-box;
  font-family: $font-family;
  overflow: hidden;
  overflow-y: hidden !important; // TODO: Check for scrollblock strategy  block body strategy
}

a:visited,
a:link {
  text-decoration: none;
}
