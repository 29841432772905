.cursor {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $z-index-7;
  pointer-events: none;
  width: 100%;
  height: 100%;

  &__vertical {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
    background: $color-active-chip;
    z-index: $z-index-7;
  }

  &__horizontal {
    position: absolute;
    height: 1px;
    left: 0;
    right: 0;
    background: $color-active-chip;
    z-index: $z-index-7;
  }
}
