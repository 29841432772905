.filter-button {
  @include reset-box-sizing;
  width: 100%;
  @include text-6;
  text-transform: capitalize;
  letter-spacing: 0;
  border: unset;

  &__container {
    border: 1px solid $color-grey-7;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $color-grey-10;
    width: 100%;
    max-height: 36px;
    padding: $spacing-3 $spacing-4 !important;
  }
  &__primary-icon {
    filter: invert(41%) sepia(0%) saturate(2%) hue-rotate(330deg) brightness(94%) contrast(84%);
  }
  &__text {
    color: $color-grey-4;
  }
  &__secondary-icon {
    color: $color-grey-3;
    right: $spacing-5;
    width: 8.75px;
    height: 4.38px;
  }
}
.full {
  .filter-button {
    &__container {
      border-color: $color-blue;
    }
    &__primary-icon {
      filter: invert(30%) sepia(88%) saturate(2431%) hue-rotate(184deg) brightness(70%) contrast(101%);
    }
    &__text {
      color: $color-blue;
    }
  }
}
