@import '@angular/material/theming';
@import '@ng-matero/extensions/theming';

// https://material.angular.io/guide/theming
// from http://mcg.mbitson.com/#!?mcgpalette0=%23006ba8
$ul-product: (
  50: #e0edf5,
  100: #b3d3e5,
  200: #80b5d4,
  300: #4d97c2,
  400: #2681b5,
  500: #006ba8,
  600: #0063a0,
  700: #005897,
  800: #004e8d,
  900: #003c7d,
  A100: #aaccff,
  A200: #77adff,
  A400: #448fff,
  A700: #2a7fff,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: $black-87-opacity,
    A700: white
  )
);

/* For use in src/lib/core/theming/_palette.scss */
$ul-accent: (
  50: #e0f5eb,
  100: #b3e7cc,
  200: #80d7aa,
  300: #4dc788,
  400: #26bb6f,
  500: #00af55,
  600: #00a84e,
  700: #009f44,
  800: #00963b,
  900: #00862a,
  A100: #b3ffc4,
  A200: #80ff9d,
  A400: #4dff76,
  A700: #33ff63,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$crimson-palette: (
  50: #ffebef,
  100: #ffcdc5,
  200: #f0999f,
  300: #e77179,
  400: #f24e58,
  500: #f83a40,
  600: #e9313e,
  700: #d72638,
  // default delete/warn colour
  800: #ca1e30,
  900: #bb0d24,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white
  )
);

$primary: mat-palette($ul-product);
$accent: mat-palette($ul-accent, 400, 200, 700);
$warn: mat-palette($crimson-palette, 700);
$theme: mat-light-theme($primary, $accent, $warn);

@include material-extensions-theme($theme);
@include material-extensions-experimental-theme($theme);

$custom-typography: mat-typography-config(
  $font-family: 'Rubik, sans-serif',
  $headline: mat-typography-level(32px, 48px, 500),
  $body-1: mat-typography-level(16px, 24px, 400),
  $title: mat-typography-level(20px, 28px, 500),
  $subheading-2: mat-typography-level(12px, 16px, 500)
);

@include mat-core($custom-typography);
@include angular-material-theme($theme);
