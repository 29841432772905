@import 'styles/abstracts';

.assign-to-assets__custom-snack-bar {
  min-width: unset !important;
}

.assign-to-assets__layer-name {
  top: $spacing-8;
  position: relative;
}

.assign-to-assets__group-name-key {
  top: $spacing-8;
  position: relative;

  .mat-option {
    height: 55px !important;
  }

  .example-option {
    @include text-7--bold;
  }
}
