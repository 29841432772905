.types-select {
  width: 210px !important;
  margin-top: $spacing-3 !important;
  max-width: none !important;
  max-height: none !important;
  padding-top: $spacing-5;
  @include text-5;
  .mat-menu-content {
    margin: 0 !important;
    padding: 0 !important;
  }
  .mat-checkbox .mat-checkbox-layout .mat-checkbox-label {
    margin-left: 10px !important;
  }
}
.type-select {
  width: 100%;
  .mat-pseudo-checkbox {
    display: none;
  }
  .mat-checkbox {
    width: 100%;
  }
  .mat-checkbox-layout {
    width: 100%;
    .mat-checkbox-label {
      width: 100%;
      height: 100%;
    }
  }
}
