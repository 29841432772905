.analysis__delete .mat-menu-content {
  padding: 0 !important;
  width: 216px;
}

.analysis__delete {
  min-height: 56px !important;
  display: flex;
  align-items: center;
}

.report-deleted {
  min-width: 160px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
