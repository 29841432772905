.analysis-select {
  margin: 0 !important;
  padding: 0 !important;
  margin-top: $spacing-3 !important;
  width: 210px;
  height: 130px;
  .mat-menu-content {
    margin: 0 !important;
    padding: 0 !important;
  }
}
