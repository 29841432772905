@import 'spacing';

// TODO: define font mixins in rems
//       define responsive type values
//       remove material font-size usage
//       remove fluid-type
//       remove excess font-weight declarations

$font-family: 'fontello', 'Rubik', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-date-time-size: 14px;

@mixin text-1 {
  font-size: 40px;
  font-weight: 400;
  line-height: 56px;
  // @media (min-width: $screen-md-min) {
  //   font-size: 40px;
  //   font-weight: 400;
  //   line-height: 56px;
  // }
}

@mixin text-1--bold {
  @include text-1;
  font-weight: 500;
}

.text-1 {
  @include text-1;
}

.text-1--bold {
  @include text-1--bold;
}

@mixin text-2 {
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
  // @media (min-width: $screen-md-min) {
  //   font-size: 40px;
  //   font-weight: 400;
  //   line-height: 56px;
  // }
}

@mixin text-2--bold {
  @include text-2;
  font-weight: 500;
}

.text-2 {
  @include text-2;
}

.text-2--bold {
  @include text-2--bold;
}

@mixin text-3 {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  // @media (min-width: $screen-md-min) {
  //   font-size: 40px;
  //   font-weight: 400;
  //   line-height: 56px;
  // }
}

@mixin text-3--bold {
  @include text-3;
  font-weight: 500;
}

.text-3 {
  @include text-3;
}

.text-3--bold {
  @include text-3--bold;
}

@mixin text-4 {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  // @media (min-width: $screen-md-min) {
  //   font-size: 40px;
  //   font-weight: 400;
  //   line-height: 56px;
  // }
}

@mixin text-4--bold {
  @include text-4;
  font-weight: 500;
}

.text-4 {
  @include text-4;
}

.text-4--bold {
  @include text-4--bold;
}

@mixin text-5 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  // @media (min-width: $screen-md-min) {
  //   font-size: 40px;
  //   font-weight: 400;
  //   line-height: 56px;
  // }
}

@mixin text-5--bold {
  @include text-5;
  font-weight: 500;
}

.text-5 {
  @include text-5;
}

.text-5--bold {
  @include text-5--bold;
}

@mixin text-6 {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  // @media (min-width: $screen-md-min) {
  //   font-size: 40px;
  //   font-weight: 400;
  //   line-height: 56px;
  // }
}

@mixin text-6--bold {
  @include text-6;
  font-weight: 500;
}

.text-6 {
  @include text-6;
}

.text-6--bold {
  @include text-6--bold;
}

@mixin text-7 {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  // @media (min-width: $screen-md-min) {
  //   font-size: 40px;
  //   font-weight: 400;
  //   line-height: 56px;
  // }
}

@mixin text-7--bold {
  @include text-7;
  font-weight: 500;
}

.text-7 {
  @include text-7;
}

.text-7--bold {
  @include text-7--bold;
}

@mixin text-8 {
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  // @media (min-width: $screen-md-min) {
  //   font-size: 40px;
  //   font-weight: 400;
  //   line-height: 56px;
  // }
}

@mixin text-8--bold {
  @include text-8;
  font-weight: 500;
}

.text-8 {
  @include text-8;
}

.text-8--bold {
  @include text-8--bold;
}

// h1 {
//   @include text-1;
// }

// h2 {
//   @include text-2;
// }

// h3 {
//   @include text-3;
// }

// h4 {
//   @include text-4;
// }
