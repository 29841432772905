@import 'styles/abstracts';
@import 'styles/components/checkbox/checkbox.scss';

$dialog-container-padding-top: $spacing-4;
$dialog-container-padding-bottom: $spacing-4;

.confirm-modelling-dialog {
  @include lt-sm {
    height: 100vh;
    width: 100vw !important;
    max-width: unset !important;
    display: flex;
    flex-direction: column;

    app-confirm-modelling-dialog {
      height: calc(100vh - #{$dialog-container-padding-bottom} - #{$dialog-container-padding-top});
      display: flex;
      flex-direction: column;
    }

    .spacer {
      flex-grow: 1;
    }
  }

  > .mat-dialog-container {
    padding-top: $dialog-container-padding-top;
    padding-bottom: $dialog-container-padding-bottom;
    height: unset;
  }

  .mat-dialog-actions {
    margin-bottom: 0;
    min-height: unset;
    padding: 0;
  }
}
