.autofly-information-panel-expanded {
  position: absolute !important;
  bottom: $spacing-5;
  left: 200px;
}

.autofly-information-panel-reduced {
  position: absolute !important;
  bottom: $spacing-5;
  left: 76px;
}
