.annotations-severity__menu {
  width: 453px;
  max-width: 453px !important;
  max-height: 450px !important;
  padding: $spacing-3 $spacing-5;
  margin-top: $spacing-5;
}
.annotations-severity__severity-levels .mat-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  letter-spacing: initial;
  line-height: initial;
}

.annotations-severity__select-icons svg {
  display: flex;
}

.annotations-severity__checkbox .mat-checkbox-inner-container {
  width: 12px;
  height: 12px;
}

.annotations-severity__severity-levels.mat-button-disabled {
  background-color: $color-grey-9 !important;
}
