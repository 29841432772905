// Border radii
$br-1: 2px;
$br-2: 4px;
$br-3: 8px;
$br-4: 16px; 
$br-5: 24px;
$br-6: 48px;
$br-7: 72px;
$br-50: 50%;
$br-100: 100%;

//Border widths
$bw-1: 1px;
$bw-2: 2px;
$bw-3: 3px;
$bw-4: 4px;
$bw-5: 6px;
$bw-6: 8px;
$bw-7: 12px;